// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import { Icon as Phosphor1 } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as localizedValues from "./n0ZMvUsbr-0.js";
const PhosphorFonts = getFonts(Phosphor);
const Phosphor1Controls = getPropertyControls(Phosphor1);

const cycleOrder = ["ZhXhcsoHp", "oyBiAVR1c"];

const serializationHash = "framer-o04Y1"

const variantClassNames = {oyBiAVR1c: "framer-v-m7bat0", ZhXhcsoHp: "framer-v-y8b323"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Active: "oyBiAVR1c", InActive: "ZhXhcsoHp"}

const getProps = ({active, height, icon, id, inActive, weight, width, ...props}) => { return {...props, G6irDsNeL: active ?? props.G6irDsNeL ?? "rgb(255, 255, 255)", pRHizpZ48: icon ?? props.pRHizpZ48 ?? "Sun", Q5dMDAlEN: inActive ?? props.Q5dMDAlEN ?? "rgb(115, 115, 115)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZhXhcsoHp", WA8k1PXV8: humanReadableEnumMap[weight] ?? weight ?? props.WA8k1PXV8 ?? "bold"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;inActive?: string;active?: string;weight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pRHizpZ48, Q5dMDAlEN, G6irDsNeL, WA8k1PXV8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZhXhcsoHp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y8b323", className, classNames)} data-framer-name={"InActive"} layoutDependency={layoutDependency} layoutId={"ZhXhcsoHp"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({oyBiAVR1c: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-8kk7s4-container"} layoutDependency={layoutDependency} layoutId={"Vkt1AeQRI-container"}><Phosphor color={Q5dMDAlEN} height={"100%"} iconSearch={"House"} iconSelection={pRHizpZ48} id={"Vkt1AeQRI"} layoutId={"Vkt1AeQRI"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={WA8k1PXV8} width={"100%"} {...addPropertyOverrides({oyBiAVR1c: {color: G6irDsNeL}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-o04Y1.framer-bblj5u, .framer-o04Y1 .framer-bblj5u { display: block; }", ".framer-o04Y1.framer-y8b323 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; padding: 0px; position: relative; width: 32px; }", ".framer-o04Y1 .framer-8kk7s4-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-o04Y1.framer-y8b323 { gap: 0px; } .framer-o04Y1.framer-y8b323 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-o04Y1.framer-y8b323 > :first-child { margin-left: 0px; } .framer-o04Y1.framer-y8b323 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"oyBiAVR1c":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pRHizpZ48":"icon","Q5dMDAlEN":"inActive","G6irDsNeL":"active","WA8k1PXV8":"weight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framern0ZMvUsbr: React.ComponentType<Props> = withCSS(Component, css, "framer-o04Y1") as typeof Component;
export default Framern0ZMvUsbr;

Framern0ZMvUsbr.displayName = "ToggleButton";

Framern0ZMvUsbr.defaultProps = {height: 32, width: 32};

addPropertyControls(Framern0ZMvUsbr, {variant: {options: ["ZhXhcsoHp", "oyBiAVR1c"], optionTitles: ["InActive", "Active"], title: "Variant", type: ControlType.Enum}, pRHizpZ48: Phosphor1Controls?.["iconSelection"] && {...Phosphor1Controls["iconSelection"], defaultValue: "Sun", description: undefined, hidden: undefined, title: "Icon"}, Q5dMDAlEN: {defaultValue: "rgb(115, 115, 115)", title: "InActive", type: ControlType.Color}, G6irDsNeL: {defaultValue: "rgb(255, 255, 255)", title: "Active", type: ControlType.Color}, WA8k1PXV8: Phosphor1Controls?.["weight"] && {...Phosphor1Controls["weight"], defaultValue: "bold", description: undefined, hidden: undefined, title: "Weight"}} as any)

addFonts(Framern0ZMvUsbr, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})